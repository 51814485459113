
.card-primary:not(.card-outline)>.card-header {
    background-color: #fff;
}


.card-title{
    color: #3e4359;
    font-weight: bold;
}
.card-header .btn-secondary{
    float: right;
    margin-right: 0.5rem;
}

.card-header .btn-primary{
    float: right;
}


.btn-primary{
    background-color: #1f6fc6;
    border-color: #1f6fc6;
}
.btn-primary:hover {
    color: #fff;
    background-color: #14477e;
    border-color: #14477e;
}

.btn-secondary{
    background-color: #ffffff;
    border-color: #969696;
    color: #000000;
}

.btn-secondary:hover {
    color: #000000;
    background-color: #d0d0d0;
    border-color: #969696;
}


.card-title {
    float: left;
    font-weight: bold;
    margin: 0;
    font-size: 1.7rem
}

.fa, .fas {
    margin: 0.2rem;
}


.card-titulo{
    background-color: #f0f0f0;
}

.card-header .card-titulo{
    background-color: #f0f0f0;
}

.layout-navbar-fixed .wrapper .sidebar-dark-primary .brand-link:not([class*=navbar]) {
    background-color: #035a85;
}


[class*=sidebar-dark-] {
    background-color: #035a85;
}

[class*=sidebar-dark] .brand-link {
    border-bottom: 1px solid #c2c7d0;
    color: rgba(255,255,255,.8);
}

[class*=sidebar-dark] .user-panel {
    border-bottom: 1px solid #c2c7d0;
}

/*   
---------------
COMPLEMENTOS  
-------------- */

.complementos{
    border: 1px solid #ccc!important;
    margin-top: 1rem;
    box-shadow: 2px 3px 6px #ccc;
}

hr {
    width: 100%;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
    border-top: 1px solid #ccc;
}

h4{
    padding: 0.8rem;
}


/* -------------------- */
/* COMPRAR FOLIOS */
/* -------------------- */
.folios .card {
    margin:1rem;
}

.folios .card-body .text-center i{
    font-size: 2.5rem;
    color: #1f6fc6;
    margin-bottom: 1rem;
}

.folios .card h5 {
    font-size: 1.5rem;
    color: #030303;
    margin-bottom:1rem;
}

.folios .card span{
    font-size: 2rem;
    font-weight: bold;
    color:#3e4359;
}
.folios .card p{
}

.folios .card .text-center{
    margin-bottom:1rem;
    margin-top:1rem;
}

.folios .card-body a{
    width:100%;
    margin-bottom:1rem;
}

.folios .card {
    border-radius: 0;
}

.oxxo img{
    width: 50%;
}

.paypal img{
    width: 30%;
}

.nav-item button{
    width: 100%;
}

/* --------------------------------
SOPORTE
-------------------------------- */

.card-soporte{
    min-height: 226px;
    margin-top: 1rem;
}

.soporte .card{
    border-radius: 0;
}

.card-soporte .card-footer{
    background-color: #fff;
}

.card-soporte .card-footer .btn{
    margin-left: 25%;
    width: 50%;
}

.usuarios img{
    width: 100px;
    height: 100px;
    margin-top: 1rem;
}

.d-factura iframe{
    width: 100%;
    height: 350px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: red;
}


.react-datepicker-wrapper {
    width: 100%;
}

.login-box  {
    width: 360px;
    margin: 7% auto;
}

.login-logo img{
    width: 90%;
}

/* autocomplete */
.no-suggestions {
    /* color: #999; */
    color:red;
    padding: 0.5rem;

    margin-top: 38px;
    position: absolute;
    background-color: white;
    z-index: 1000;
  }
  
  .suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    /* margin-top: 0; */
    max-height: 200px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(300px + 1rem);

    margin-top: 38px;
    position: absolute;
    background-color: white;
    z-index: 1000;
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: #0062cc;
    color: #ffffff;
    cursor: pointer;
    font-weight: 700;
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }
  